body {
  margin: 0;
  padding: 0;
  background-color: #4c3a51;
}

.task-manager {
  text-align: center;
}
.main-heading {
  color: #e7ab79;
}
