.task-item {
  display: flex;
  border: 0 solid wheat;
  align-items: center;
  gap: 25px;
  box-shadow: 0 0 12px grey;
  margin-top: 25px;
  padding: 5px;
  width: 70vw;
  transition: all 1s;
}
.task-item:hover,
.icon-remove {
  transform: scale(1.1);
}
.task-container {
  display: inline-block;
  text-align: left;
}

.completed {
  color: black;
  text-decoration: line-through;
  text-decoration-thickness: 5px;
}

.icon-remove {
  cursor: pointer;
  color: red;
}

.task-title {
  width: 95%;
  color: #f2d7d9;
}
