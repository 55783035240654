.task-input-form {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.task-input-title {
  flex-basis: 57%;
  min-height: 50px;
  font-size: 1.5rem;
}
.add-task {
  background-color: #b25068;
  width: 150px;
  font-size: 22px;
}
.task-exist {
  flex: 0 0 100%;
  color: red;
}
.task-input-title-error {
  border-color: red;
  color: red;
}
.new-task {
  display: none;
}
@media only screen and (max-width: 400px) {
  .task-input-form {
    flex-direction: column;
    align-items: center;
  }
  .task-input-title {
    width: 80%;
  }
  .add-task {
    width: 35%;
    height: 30px;
  }
}
